import { Temporal } from "@js-temporal/polyfill";
import { Copyable } from "../../common_util";
import { StorefrontConfig } from "./storefront";

export type FulfillmentMethod = "delivery" | "pickup";
export type Product = ProductLeaf | ProductBranch;
export type Category = CategoryLeaf | CategoryBranch;

abstract class CatalogComponent extends Copyable {
  key: string;
  keyPrefix: string[];
  title: string;

  constructor(key: string, keyPrefix: string[], title: string) {
    super();

    this.key = key;
    this.keyPrefix = keyPrefix;
    this.title = title;
  }

  abstract findChildWithKey(key: string): CatalogComponent | undefined;

  keyForUrl() : string {
    return encodeURIComponent(this.key);
  }

  keyPath(): string {
    return `${this.keyPrefix.join(";")};${this.key}`;
  }

  encodedKeyPath(): string {
    return this.fullKeyPath().join(";");
  }

  fullKeyPath(): string[] {
    return this.keyPrefix.map((prefix) => encodeURIComponent(prefix)).concat([encodeURIComponent(this.key)]);
  }
}

abstract class CategoryBase extends CatalogComponent {
  description: string | undefined;

  constructor(key: string, keyPrefix: string[], title: string, description: string | undefined) {
    super(key, keyPrefix, title);

    this.description = description;
  }

  abstract getProductPreview(productCount: number): Product[];
}

interface ICategoryLeaf { kind: "leaf" }
export class CategoryLeaf extends CategoryBase implements ICategoryLeaf {
  kind: "leaf" = "leaf" as const;
  products: Product[];

  constructor(key: string, keyPrefix: string[], title: string, products: Product[], description?: string) {
    super(key, keyPrefix, title, description);
    this.products = products;
  }

  findTopLevelProductByKeyFragment(key: string): Product[] {
    return this.products.filter((product) => product.key === key);
  }

  findChildWithKey(key: string) {
    return this.products.find(product => {
      return product.key == key;
    });
  }

  getProductPreview(productCount: number): Product[] {
    return this.products.sort((a, b) => {
      if (a.images.length > b.images.length) return -1;
      else if (b.images.length > a.images.length) return 1;
      else return 0;
    }).slice(0, productCount);
  }
}

interface ICategoryBranch { kind: "branch" }
export class CategoryBranch extends CategoryBase implements ICategoryBranch {
  kind: "branch" = "branch" as const;
  categories: Category[];

  constructor(key: string, keyPrefix: string[], title: string, categories: Category[], description?: string) {
    super(key, keyPrefix, title, description);
    this.categories = categories;
  }

  findTopLevelProductByKeyFragment(key: string) {
    return this.categories.reduce<Product[]>((foundProducts, category) => {
      foundProducts.push(...category.findTopLevelProductByKeyFragment(key));

      return foundProducts;
    }, []);
  }

  findChildWithKey(key: string) {
    return this.categories.find(category => {
      return category.key == key;
    });
  }

  getProductPreview(productCount: number): Product[] {
    let countRemaining = productCount;
    let products: Product[] = [];

    for (let i = 0; i < this.categories.length && countRemaining > 0; i++) {
      products = products.concat(this.categories[i].getProductPreview(countRemaining));

      countRemaining = countRemaining - products.length;
    }

    return products;
  }
}

abstract class ProductBase extends CatalogComponent {
  kind: "leaf" | "branch";
  sku?: string;
  description: string | undefined;
  images: string[];
  price: number;
  maxQuantity?: number;
  tags: string[];
  defaultQuantity?: number;
  restrictions?: string[];

  constructor(
    kind: "leaf" | "branch",
    key: string,
    keyPrefix: string[],
    title: string,
    price: number,
    tags: string[],
    images: string[],
    sku?: string,
    description?: string,
    maxQuantity?: number,
    defaultQuantity?: number,
    restrictions?: string[],
  ) {
    super(key, keyPrefix, title);

    this.kind = kind;
    this.sku = sku;
    this.price = price;
    this.description = description;
    this.images = images;
    this.maxQuantity = maxQuantity;
    this.defaultQuantity = defaultQuantity;
    this.tags = tags;
    this.restrictions = restrictions;
  }
}

interface IProductLeaf { kind: "leaf" }
export class ProductLeaf extends ProductBase implements IProductLeaf {
  kind: "leaf" = "leaf" as const;

  constructor({
    key,
    keyPrefix,
    title,
    price,
    tags,
    sku,
    description,
    images,
    maxQuantity,
    defaultQuantity,
    restrictions
  } : {
    key: string;
    keyPrefix: string[];
    title: string;
    price: number;
    tags: string[];
    images: string[];
    sku?: string;
    description?: string;
    maxQuantity?: number;
    defaultQuantity?: number;
    restrictions?: string[];
  }) {
    super("leaf", key, keyPrefix, title, price, tags, images, sku, description, maxQuantity, defaultQuantity, restrictions);
  }

  findChildWithKey(_key: string) {
    // No child
    return undefined;
  }
}

interface IProductBranch { kind: "branch" }
export class ProductBranch extends ProductBase implements IProductBranch {
  kind: "branch" = "branch" as const;

  selectionGroups: SelectionGroup[];

  constructor({
    key,
    keyPrefix,
    title,
    price,
    selectionGroups,
    tags,
    sku,
    description,
    images,
    maxQuantity,
    defaultQuantity,
    restrictions
  } : {
    key: string;
    keyPrefix: string[];
    title: string;
    price: number;
    selectionGroups: SelectionGroup[];
    tags: string[];
    images: string[];
    sku?: string;
    description?: string;
    maxQuantity?: number;
    defaultQuantity?: number;
    restrictions?: string[];
    }) {
    super("branch", key, keyPrefix, title, price, tags, images, sku, description, maxQuantity, defaultQuantity, restrictions);
    this.selectionGroups = selectionGroups;
  }

  requiredSelectionGroups() {
    return this.selectionGroups.filter(sg => sg.isRequired());
  }

  findChildWithKey(key: string) {
    return this.selectionGroups.find(selectionGroup => {
      return selectionGroup.key == key;
    });
  }
}

export class SelectionGroup extends CatalogComponent {
  readonly products: Product[];
  readonly minSelections: number;
  readonly maxSelections?: number;
  readonly description?: string;

  constructor(
    key: string,
    keyPrefix: string[],
    title: string,
    products: Product[],
    min: number,
    max?: number,
    description?: string
  ) {
    super(key, keyPrefix, title);

    this.key = key;
    this.description = description;
    this.products = products;
    this.minSelections = min;
    this.maxSelections = max;
  }

  isRequired(): boolean {
    return this.minSelections > 0;
  }

  findChildWithKey(key: string) {
    return this.products.find(product => {
      return product.key == key;
    });
  }
}

export class Section extends CatalogComponent {
  categories: Category[];
  description?: string;
  fulfillmentMethods: FulfillmentMethod[];
  availabilities: Availability[];

  constructor({
    key,
    keyPrefix,
    title,
    categories,
    fulfillmentMethods,
    description,
    availabilities,
  } : {
    key: string;
    keyPrefix: string[];
    title: string;
    categories: Category[];
    fulfillmentMethods: FulfillmentMethod[];
    description?: string;
    availabilities: Availability[];
  }) {
    super(key, keyPrefix, title);

    this.description = description;
    this.categories = categories;
    this.fulfillmentMethods = fulfillmentMethods;
    this.availabilities = availabilities;
  }

  findTopLevelProductByKeyFragment(key: string): Product[] {
    return this.categories.reduce<Product[]>((products, category) => {
      products.push(...category.findTopLevelProductByKeyFragment(key));

      return products;
    }, [])
  }

  findChildWithKey(key: string) {
    return this.categories.find(category => {
      return category.key == key;
    });
  }
}

export class Catalog extends Copyable {
  readonly sections: Section[];
  readonly availabilityCache: Map<string, boolean>;

  constructor(sections: Section[]) {
    super();

    this.sections = sections;
    this.availabilityCache = new Map<string, boolean>;
  }

  findProductForKey(key: string): Product | null {
    const keyFragments = key.split(";");

    if (keyFragments.length >= 3) {
      const sectionKey = keyFragments.shift();
      const section = this.sections.find(section => {
        return encodeURIComponent(section.key) == sectionKey;
      });

      if (section) {
        const productKey = keyFragments.pop();

        const categories : Category[] = [];
        keyFragments.reduce<Category[] | null>((prev, fragmentOfHash) => {
          const nextCat = prev?.find(c => c.keyForUrl() == fragmentOfHash);

          if (nextCat) {
            categories.push(nextCat);

            if (nextCat && nextCat.kind == "branch")
              return nextCat.categories;
            else
              return null;
          }
          else
            return null;
        }, section.categories);

        const category = categories[categories.length - 1];
        if (category && category.kind == "leaf") {
          const product = category.products.find(product => {
            return product.keyForUrl() == productKey;
          });

          if (product) {
            return product;
          }
        }
      }
    }

    return null;
  }

  findCatalogComponentFromKeyPath(keyFragments: string[]): CatalogComponent | undefined {
    const components = this.findCatalogComponentsFromKeyPath(keyFragments);

    return components[components.length - 1];
  }


  findCatalogComponentsFromKeyPath(keyFragments: string[]): CatalogComponent[] {
    const components: CatalogComponent[] = [];

    let fragment = keyFragments.shift();
    if (!fragment) return components;
    let child: CatalogComponent | undefined = this.findChildWithKey(decodeURIComponent(fragment));
    if (!child) return components;
    components.push(child);

    while (keyFragments.length > 0) {
      fragment = keyFragments.shift();
      if (!fragment) return components;

      child = child.findChildWithKey(decodeURIComponent(fragment));
      if (!child) return components;

      components.push(child);
    }

    return components;
  }

  findTopLevelProductByKeyFragment(key: string): Product | null {
    const matchingProducts = this.sections.reduce<Product[]>((acc, section) => {
      acc.push(...section.findTopLevelProductByKeyFragment(key));

      return acc;
    }, []);

    return matchingProducts[0];
  }

  findChildWithKey(key: string) {
    return this.sections.find(section => {
      return section.key == key;
    });
  }

  isInFulfillableTimeRange(config: StorefrontConfig, { keyPrefix }: { keyPrefix: string[] }): boolean {
    const relevantSection = this.sections.find(section => section.key === keyPrefix[0]);

    if (!relevantSection)
      return false;

    return this.isAvailableForPreferredTime(config, relevantSection);
  }

  isAvailableForPreferredTime(config: StorefrontConfig, relevantSection: Section) {
    const cachedResult = this.availabilityCache.get(relevantSection.key);

    if (cachedResult !== undefined) {
      return cachedResult;
    }

    const preferred = config.preferredOrNextTime();

    if (!preferred)
      return false;

    const preferredPickupTime = preferred.subtract({ minutes: config.timeOffset } )

    const derivedResult = relevantSection.availabilities.filter((av) => {
      const afterStart = Temporal.ZonedDateTime.compare(preferredPickupTime.withPlainTime(av.startTime), preferredPickupTime) <= 0;
      const beforeEnd = Temporal.ZonedDateTime.compare(preferredPickupTime, preferredPickupTime.withPlainTime(av.endTime)) <= 0;

      return (av.wday === preferredPickupTime.dayOfWeek % 7) && afterStart && beforeEnd;
    }).length > 0;

    this.availabilityCache.set(relevantSection.key, derivedResult);

    return derivedResult;
  }
}

export class OutOfStockList {
  readonly loadedAt: Temporal.Instant;
  readonly outOfStockListItems: OutOfStockListItem[];

  constructor({
    loadedAt,
    outOfStockListItems
  }: {
    loadedAt: Temporal.Instant;
    outOfStockListItems: OutOfStockListItem[];
  }) {
    this.loadedAt = loadedAt;
    this.outOfStockListItems = outOfStockListItems;
  }

  isProductInOutage({ key, sku }: { key?: string; sku?: string }): boolean {
    return this.outOfStockListItems?.some(x => {
      return ((x.key && key && x.key === key) || (x.sku && sku && x.sku == sku)) && Temporal.Instant.compare(this.loadedAt, x.snoozeStart) >= 0 && Temporal.Instant.compare(this.loadedAt, x.snoozeEnd) <= 0;
    }) ?? false;
  }
}

export class OutOfStockListItem {
  readonly snoozeStart: Temporal.Instant;
  readonly snoozeEnd: Temporal.Instant;
  readonly key?: string;
  readonly sku?: string;

  constructor({
    snoozeStart,
    snoozeEnd,
    key,
    sku
  } : {
    snoozeStart: Temporal.Instant;
    snoozeEnd: Temporal.Instant;
    key?: string;
    sku?: string;
    }) {
    this.snoozeStart = snoozeStart;
    this.snoozeEnd = snoozeEnd;
    this.key = key;
    this.sku = sku;
  }
}

export class Availability {
  readonly startTime: Temporal.PlainTime;
  readonly endTime: Temporal.PlainTime;
  readonly wday: number;

  constructor(
    startTime: Temporal.PlainTime,
    endTime: Temporal.PlainTime,
    wday: number
  ) {
    this.startTime = startTime;
    this.endTime = endTime;
    this.wday = wday;
  }

  covers(time: Temporal.PlainTime): boolean {
    return Temporal.PlainTime.compare(this.startTime, time) <= 0 && Temporal.PlainTime.compare(time, this.endTime) < 0
  }

  entirelyBefore(time: Temporal.PlainTime): boolean {
    return Temporal.PlainTime.compare(this.startTime, time) <= 0 && Temporal.PlainTime.compare(this.endTime, time) <= 0
  }

  dayOfWeek() {
    return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][this.wday];
  }
}
