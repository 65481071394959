import { JsonDecoder, ok, err } from "ts.data.json";
import {
  CategoryLeaf, CategoryBranch, ProductLeaf, ProductBranch,
  Category, Product, Section, SelectionGroup, Catalog,
  OutOfStockListItem,
  FulfillmentMethod,
  Availability,
  OutOfStockList,
} from "./types/catalog";

import {
  Cart, CartItem, CartItemSelectionGroup
} from "./types/cart";
import { Temporal } from "@js-temporal/polyfill";
import { Deal, RestrictionType } from "src/storefront/types/storefront";
import { IdTarget } from "src/storefront/types/id_target";

const CategoryLeafDecoder = JsonDecoder.object<ICategoryLeaf>({
  kind: JsonDecoder.constant("leaf"),
  key: JsonDecoder.string,
  title: JsonDecoder.string,
  products: JsonDecoder.lazy(() => JsonDecoder.array(ProductDecoder, "Product[]")),
  description: JsonDecoder.optional(JsonDecoder.string),
}, "CategoryLeaf");

const CategoryBranchDecoder : JsonDecoder.Decoder<ICategoryBranch> = JsonDecoder.object<ICategoryBranch>({
  kind: JsonDecoder.constant("branch"),
  key: JsonDecoder.string,
  title: JsonDecoder.string,
  categories: JsonDecoder.lazy(() => JsonDecoder.array<ICategory>(CategoryDecoder, "Category[]")),
  description: JsonDecoder.optional(JsonDecoder.string),
}, "CategoryBranch");

const ProductLeafDecoder : JsonDecoder.Decoder<IProductLeaf> = JsonDecoder.object<IProductLeaf>({
  kind: JsonDecoder.constant("leaf"),
  key: JsonDecoder.string,
  sku: JsonDecoder.optional(JsonDecoder.string),
  title: JsonDecoder.string,
  price: JsonDecoder.number,
  tags: JsonDecoder.optional(JsonDecoder.array(JsonDecoder.string, "tag[]")),
  description: JsonDecoder.optional(JsonDecoder.string),
  images: JsonDecoder.optional(JsonDecoder.array(JsonDecoder.string, "images[]")),
  max: JsonDecoder.optional(JsonDecoder.number),
  default_quantity: JsonDecoder.optional(JsonDecoder.number),
  restrictions: JsonDecoder.optional(JsonDecoder.array(JsonDecoder.enumeration(RestrictionType, "RestrictionType"), "restrictions[]")),
}, "ProductLeaf");

const ProductBranchDecoder : JsonDecoder.Decoder<IProductBranch> = JsonDecoder.object({
  kind: JsonDecoder.constant("branch"),
  key: JsonDecoder.string,
  sku: JsonDecoder.optional(JsonDecoder.string),
  title: JsonDecoder.string,
  price: JsonDecoder.number,
  selection_groups: JsonDecoder.lazy(() => JsonDecoder.allOf(JsonDecoder.array(SelectionGroupDecoder, "SelectionGroup[]"), nonEmpty)),
  tags: JsonDecoder.optional(JsonDecoder.array(JsonDecoder.string, "tag[]")),
  description: JsonDecoder.optional(JsonDecoder.string),
  images: JsonDecoder.optional(JsonDecoder.array(JsonDecoder.string, "images[]")),
  max: JsonDecoder.optional(JsonDecoder.number),
  restrictions: JsonDecoder.optional(JsonDecoder.array(JsonDecoder.string, "restrictions[]")),
}, "ProductBranch");


const CategoryDecoder = JsonDecoder.oneOf<ICategory>([CategoryBranchDecoder, CategoryLeafDecoder], "CategoryDecoder");
const ProductDecoder = JsonDecoder.oneOf<IProduct>([ProductBranchDecoder, ProductLeafDecoder], "ProductDecoder");

const PlainTimeDecoder: JsonDecoder.Decoder<Temporal.PlainTime> = JsonDecoder.string.map((str) => {
  return Temporal.PlainTime.from(str, { overflow: 'reject' });
});

const SelectionGroupDecoder: JsonDecoder.Decoder<ISelectionGroup> = JsonDecoder.object({
  key: JsonDecoder.string,
  title: JsonDecoder.string,
  description: JsonDecoder.optional(JsonDecoder.string),
  min: JsonDecoder.failover(0, JsonDecoder.number),
  max: JsonDecoder.optional(JsonDecoder.number),
  products: JsonDecoder.array(ProductDecoder, "Product[]"),
}, "SelectionGroup");

const AvailabilityDecoder: JsonDecoder.Decoder<IAvailability> = JsonDecoder.object({
  start_time: PlainTimeDecoder,
  end_time: PlainTimeDecoder,
  wday: JsonDecoder.number,
}, "Availability");

const DelivereasyFulfillmentDecoder = JsonDecoder.isExactly("Delivereasy");
const CustomerPickupFulfillmentDecoder = JsonDecoder.isExactly("Customer pickup");
const DeliveryFulfillmentDecoder = JsonDecoder.isExactly("delivery");
const PickupFulfillmentDecoder = JsonDecoder.isExactly("pickup");
const FulfillmentMethodDecoder = JsonDecoder.oneOf([DelivereasyFulfillmentDecoder, CustomerPickupFulfillmentDecoder, DeliveryFulfillmentDecoder, PickupFulfillmentDecoder], "FulfillmentMethod");

const SectionDecoder = JsonDecoder.object<ISection>({
  key: JsonDecoder.string,
  title: JsonDecoder.string,
  categories: JsonDecoder.array(CategoryDecoder, "Category[]"),
  fulfillment_methods: JsonDecoder.array(FulfillmentMethodDecoder, "FulfillmentMethod[]"),
  description: JsonDecoder.optional(JsonDecoder.string),
  availabilities: JsonDecoder.array(AvailabilityDecoder, "Availability[]"),
}, "Section"); // .map(d => new Section(d.key, d.title, d.description, d.categories));

const CatalogDecoder: JsonDecoder.Decoder<ICatalog> = JsonDecoder.object({
  sections: JsonDecoder.array(SectionDecoder, "Section[]"),
}, "Catalog"); // .map(d => new Catalog(d.sections));

 // eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseCatalog = (c: any) : Catalog => {
  return CatalogDecoder.fold(tree => {
    return assembleCatalog(tree);
  }, err => {
    throw err;
  }, c);
};

interface IAvailability {
  start_time: Temporal.PlainTime;
  end_time: Temporal.PlainTime;
  wday: number;
}


interface ICatalog {
  sections: ISection[];
}

interface ISection {
  key: string;
  title: string;
  categories: ICategory[];
  fulfillment_methods: string[];
  description?: string;
  availabilities: IAvailability[];
}

interface ISelectionGroup {
  key: string;
  title: string;
  products: IProduct[];
  min: number;
  max?: number;
  description?: string;
}

interface ICategoryLeaf {
  kind: "leaf";
  key: string;
  title: string;
  products: IProduct[];
  description?: string;
}

interface ICategoryBranch {
  kind: "branch";
  key: string;
  title: string;
  categories: ICategory[];
  description?: string;
}

interface IProductLeaf {
  kind: "leaf";
  key: string;
  sku?: string;
  title: string;
  price: number;
  tags?: string[];
  description?: string;
  images?: string[];
  max?: number;
  default_quantity?: number;
  restrictions?: string[];
}

interface IProductBranch {
  kind: "branch";
  key: string;
  sku? :string;
  title: string;
  price: number;
  selection_groups: ISelectionGroup[];
  tags?: string[];
  description?: string;
  images?: string[];
  max?: number;
  restrictions?: string[];
}

type ICategory = ICategoryLeaf | ICategoryBranch;
type IProduct = IProductLeaf | IProductBranch;

const assembleCatalog = ({ sections }: ICatalog ) : Catalog => {
  return new Catalog(sections.map(s => assembleSection(s)));
};

const assembleSection = ({ key, title, description, fulfillment_methods, categories, availabilities }: ISection) : Section => {
  const coerceFulfillmentMethod = (c: string): FulfillmentMethod => {
    switch (c) {
      case "Customer pickup": return "pickup";
      case "Delivereasy": return "delivery"
      default: return c as FulfillmentMethod;
    }
  }

  return new Section({
    key: key,
    keyPrefix: [],
    title: title,
    categories: categories.map(c => assembleCategory(c, [key])),
    fulfillmentMethods: fulfillment_methods.map(c => coerceFulfillmentMethod(c)),
    description: description,
    availabilities: availabilities.map(a => assembleAvailability(a)),
  });
}

const assembleAvailability = ({ start_time, end_time, wday }: IAvailability) : Availability => {
  return new Availability(
    start_time,
    end_time,
    wday,
  );
}

const assembleCategoryLeaf = ({ key, title, products, description }: ICategoryLeaf, keyPrefix: string[]): CategoryLeaf => {
  return new CategoryLeaf(key, keyPrefix, title, products.map(p => assembleProduct(p, keyPrefix.concat(key))), description);
};

const assembleCategoryBranch = ({ key, title, categories, description }: ICategoryBranch, keyPrefix: string[]): CategoryBranch => {
  return new CategoryBranch(key, keyPrefix, title, categories.map(c => assembleCategory(c, keyPrefix.concat(key))), description);
}

const assembleCategory = (c: ICategory, keyPrefix: string[]) : Category => {
  if (c.kind == "leaf")
    return assembleCategoryLeaf(c, keyPrefix);
  else
    return assembleCategoryBranch(c, keyPrefix);
}

const assembleProductLeaf = ({ key, title, price, tags, sku, description, images, max, default_quantity, restrictions }: IProductLeaf, keyPrefix: string[]): ProductLeaf => {
  return new ProductLeaf({
    key: key,
    keyPrefix: keyPrefix,
    title: title,
    price: price,
    tags: tags || [],
    sku: sku,
    description: description,
    images: images || [],
    maxQuantity: max,
    defaultQuantity: default_quantity,
    restrictions: restrictions,
  });
}

const assembleProductBranch = ({ key, title, price, selection_groups, tags, sku, description, images, max, restrictions }: IProductBranch, keyPrefix: string[]): ProductBranch => {
  const selectionGroups = selection_groups.map(sg => assembleSelectionGroup(sg, keyPrefix.concat(key)));
  const score = (sg: SelectionGroup) : number => {
    if (sg.minSelections === 1 && sg.maxSelections === 1)
      return 2;
    else if (sg.minSelections === 1)
      return 1;
    else
      return 0;
  };

  selectionGroups.sort((a, b) => {
    return score(b) - score(a);
  });

  return new ProductBranch({
    key: key,
    keyPrefix: keyPrefix,
    title: title,
    price: price,
    selectionGroups: selectionGroups,
    tags: tags || [],
    sku: sku,
    description: description,
    images: images || [],
    maxQuantity: max,
    restrictions: restrictions,
  });
}

const assembleProduct = (p: IProduct, keyPrefix: string[]) : Product => {
  if (p.kind == "leaf")
    return assembleProductLeaf(p, keyPrefix);
  else
    return assembleProductBranch(p, keyPrefix);
};

const assembleSelectionGroup = ({ key, title, products, min, max, description }: ISelectionGroup, keyPrefix: string[]) : SelectionGroup => {
  return new SelectionGroup(
    key,
    keyPrefix,
    title,
    products.map(p => assembleProduct(p, keyPrefix.concat(key))),
    min,
    max,
    description,
  );
};

const nonEmpty = new JsonDecoder.Decoder<SelectionGroup[]>((json: SelectionGroup[]) => {
  if (json.length > 0) {
    return ok(json);
  } else {
    return err('Set is empty');
  }
});

const CartItemSelectionGroupDecoder: JsonDecoder.Decoder<ICartItemSelectionGroup> = JsonDecoder.object({
  key: JsonDecoder.string,
  key_prefix: JsonDecoder.array(JsonDecoder.string, "KeyPrefix"),
  name: JsonDecoder.string,
  cart_items: JsonDecoder.lazy(() => JsonDecoder.array(CartItemDecoder, "CartItem[]")),
}, "CartItemSelectionGroup"); // .

const CartItemDecoder: JsonDecoder.Decoder<ICartItem> = JsonDecoder.object({
  key: JsonDecoder.string,
  key_prefix: JsonDecoder.array(JsonDecoder.string, "KeyPrefix"),
  name: JsonDecoder.string,
  description: JsonDecoder.optional(JsonDecoder.string),
  price: JsonDecoder.number,
  tags: JsonDecoder.array(JsonDecoder.string, "Tags"),
  quantity: JsonDecoder.number,
  meal_preferences: JsonDecoder.optional(JsonDecoder.string),
  selection_groups: JsonDecoder.array(CartItemSelectionGroupDecoder, "SelectionGroup[]"),
}, "CartItem"); // .

const CartDecoder = JsonDecoder.object<IResponse>({
  bag: JsonDecoder.array(CartItemDecoder, "CartItem[]"),
  uuid: JsonDecoder.string,
  discount: JsonDecoder.number,
  delivery_fee: JsonDecoder.number,
  subscription_delivery_fee_savings: JsonDecoder.number,
  service_fee: JsonDecoder.number,
  eligible_free_product_keys: JsonDecoder.array(JsonDecoder.string, "FreeProducts[]"),
}, "Cart");

 // eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseCart = (c: any) : Cart => {
  return CartDecoder.fold(tree => {
    return assembleCart({ cart_items: tree.bag }, tree.uuid.toString(), tree.delivery_fee, tree.subscription_delivery_fee_savings ,tree.service_fee, tree.discount, tree.eligible_free_product_keys);
  }, err => {
    throw err;
  }, c);
};

interface IResponse {
  bag: ICartItem[];
  uuid: string;
  delivery_fee: number;
  subscription_delivery_fee_savings: number;
  service_fee: number;
  discount: number;
  eligible_free_product_keys: string[];
}

export interface ICart {
  cart_items: ICartItem[];
}

export interface ICartItem {
  key: string;
  key_prefix: string[];
  name: string;
  description?: string;
  price: number;
  tags: string[];
  quantity: number;
  meal_preferences?: string;
  selection_groups: ICartItemSelectionGroup[];
}

export interface ICartItemSelectionGroup {
  key: string;
  key_prefix: string[];
  name: string;
  cart_items: ICartItem[];
}

export interface IProductDealDetails {
  product_keys: Array<string>;
  product_deal_text: string;
}

const ProductDealDecoder = JsonDecoder.object<IProductDealDetails>({
  product_keys: JsonDecoder.array(JsonDecoder.string, "ProductKeys[]"),
  product_deal_text: JsonDecoder.string,
}, "ProductDeal");

export interface IDeal {
  id: number;
  title: string;
  description: string;
  terms_and_conditions?: string;
  product_details?: IProductDealDetails;
}

const DealDecoder = JsonDecoder.object<IDeal>({
  id: JsonDecoder.number,
  title: JsonDecoder.string,
  description: JsonDecoder.string,
  terms_and_conditions: JsonDecoder.optional(JsonDecoder.string),
  product_details: JsonDecoder.optional(ProductDealDecoder),
}, "Deal");

const DealListDecoder = JsonDecoder.array<IDeal>(DealDecoder, "DealList");

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseDealList = (d: any[]) : Deal[] => {
  return DealListDecoder.fold(deals => {
    return deals.map(deal => assembleDeal(deal));
  }, err => {
    throw err;
  }, d)
}

const assembleDeal = (deal: IDeal) : Deal => {
  const assembledDeal: Deal = { id: deal.id, title: deal.title, description: deal.description, termsAndConditions: deal.terms_and_conditions }

  if (deal.product_details) {
    assembledDeal.productDetails = { productKeys: deal.product_details.product_keys, productDealText: deal.product_details.product_deal_text }
  }

  return assembledDeal;
}

const assembleCart = ({ cart_items }: ICart, id: string, deliveryFee: number, subscriptionDeliveryFeeSavings: number, serviceFee: number, discount: number, eligible_free_product_keys: string[]): Cart => {
  return new Cart(id, cart_items.map(c => assembleCartItem(c)), deliveryFee, subscriptionDeliveryFeeSavings, serviceFee, discount, eligible_free_product_keys);
}

const assembleCartItem = (cart_item: ICartItem) : CartItem => {
  return new CartItem(
    cart_item.key,
    cart_item.key_prefix,
    cart_item.name,
    cart_item.price,
    cart_item.quantity,
    cart_item.selection_groups.map(sg => assembleCartItemSelectionGroup(sg)),
    new IdTarget(),
    cart_item.description,
    cart_item.meal_preferences,
  );
};

const assembleCartItemSelectionGroup = (cartItemSelectionGroup: ICartItemSelectionGroup) : CartItemSelectionGroup => {
  return new CartItemSelectionGroup(
    cartItemSelectionGroup.key,
    cartItemSelectionGroup.key_prefix,
    cartItemSelectionGroup.name,
    cartItemSelectionGroup.cart_items.map(c => assembleCartItem(c)),
  );
}

export interface IOutOfStockListItem {
  snooze_start: string;
  snooze_end: string;
  key?: string;
  sku?: string;
}

const OutageListItemDecoder : JsonDecoder.Decoder<IOutOfStockListItem> = JsonDecoder.object({
  snooze_start: JsonDecoder.string,
  snooze_end: JsonDecoder.string,
  key: JsonDecoder.optional(JsonDecoder.string),
  sku: JsonDecoder.optional(JsonDecoder.string),
}, "IOutageListItem");

const OutOfStockListDecoder: JsonDecoder.Decoder<IOutOfStockListItem[]> = JsonDecoder.array(OutageListItemDecoder, "IOutageListItem[]");

const assembleOutOfStockList = (loadedAt: Temporal.Instant, items: IOutOfStockListItem[]): OutOfStockList => {
  return new OutOfStockList({
    loadedAt: loadedAt,
    outOfStockListItems: items.map(assembleOutOfStockListItem),
  });
}

const assembleOutOfStockListItem = (item: IOutOfStockListItem) : OutOfStockListItem => {
  return new OutOfStockListItem({
    snoozeStart: Temporal.Instant.from(item.snooze_start),
    snoozeEnd: Temporal.Instant.from(item.snooze_end),
    key: item.key,
    sku: item.sku,
  });
}

 // eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseOutOfStockList = (loadedAt: Temporal.Instant, c: any) : OutOfStockList => {
  return OutOfStockListDecoder.fold(d => {
    return assembleOutOfStockList(loadedAt, d);
  }, err => {
    throw err;
  }, c);
}
